import React from 'react';
import Layout from "../components/Layout";

export default function FAQsForEnrolledParticipants(){
    return(
        <Layout>
        <div>
        <h1 style={{color:'#3399f3', marginBottom:'30px'}}>Frequently Asked Questions for enrolled participants</h1>
        <div class="overflow-auto" style={{fontSize:'1.2em'}} className='faqContent'>
            <p>Below are some questions our returning participants ask.</p> 
            <ul> 
                <li><a href="#i-thought-this-was-a-one-time-thing-why-am-i-being-asked-to-do-another-interview" className='callLink'>I thought this was a one-time thing, why am I being asked to do another interview?</a></li> 
                <li><a href="#why-is-someone-from-your-staff-is-always-calling-me" className='callLink'>Why is someone from your staff is always calling me?</a></li> 
                <li><a href="#will-i-have-to-sign-more-paperwork" className='callLink'>Will I have to sign more paperwork?</a></li> 
                <li><a href="#my-or-my-loved-ones-condition-has-worsened-can-we-still-participate" className='callLink'>My or my loved one’s condition has worsened, can we still participate?</a></li> 
                <li><a href="#my-study-partner-can-no-longerdoes-not-want-to-participate-can-i-still-continue" className='callLink'>My study partner can no longer/does not want to participate, can I still continue?</a></li> 
                <li><a href="#my-study-partner-has-passed-away-why-am-I-being-contacted-to-do-more" className='callLink'>My study partner has passed away, why am I being contacted to do more?</a></li> 
                <li><a href="#how-will-doing-this-help-me" className='callLink'>How will doing this help me?</a></li>
            </ul> 
            <h2><a href="#i-thought-this-was-a-one-time-thing-why-am-i-being-asked-to-do-another-interview" id="i-thought-this-was-a-one-time-thing-why-am-i-being-asked-to-do-another-interview" className='callLink'>I thought this was a one-time thing, why am I being asked to do another interview?</a></h2> 
            <p>If you are a returning participant, you may remember doing your first interview about two years ago. This first interview is called our baseline interview, and the information we get from it help our researchers compare future answers to understand how memory changes. After the baseline interview, we conduct another interview every two years to see how the responses we get change over time.</p> 
            <h2><a href="#why-is-someone-from-your-staff-is-always-calling-me" id="why-is-someone-from-your-staff-is-always-calling-me" className='callLink'>Why is someone from your staff is always calling me?</a></h2> 
            <p>When we did your initial interview, we asked for your preferred method of contact. We contact our participants four times a year to ensure we have the most accurate contact information and see how our participants are doing.</p> 
            <h2><a href="#will-i-have-to-sign-more-paperwork" id="will-i-have-to-sign-more-paperwork" className='callLink'>Will I have to sign more paperwork?</a></h2> 
            <p>When we first began our study the paperwork participants signed covered all the elements that would be asked in the two-year interview. Since that baseline interview, some new elements have been added to our study so we can get a better understanding of decisions that people may make for their own healthcare or those considered when caring for someone. We have also added a four-year interview. To ensure all of our participants are well-informed about participating, we must sign new paperwork that includes these additional elements.</p> 
            <h2><a href="#my-or-my-loved-ones-condition-has-worsened-can-we-still-participate" id="my-or-my-loved-ones-condition-has-worsened-can-we-still-participate" className='callLink'>My or my loved one’s condition has worsened, can we still participate?</a></h2> 
            <p>We understand that a lot can happen in two years, including changes in health, financial situation, and housing situations. We encourage our participants to continue their participation so that we can get an accurate representation of responses. If you are worried that the changes may make participation difficult, (for example: now the person has trouble writing, or their memory has further deteriorated) we will simply do as much as we can and make accommodations when necessary. Even if we cannot get all of the answers, it is still very important to get as much of your experience represented as possible.</p> 
            <h2><a href="#my-study-partner-can-no-longerdoes-not-want-to-participate-can-i-still-continue" id="my-study-partner-can-no-longerdoes-not-want-to-participate-can-i-still-continue" className='callLink'>My study partner can no longer/does not want to participate, can I still continue?</a></h2> 
            <p>If you are the person whose memory was being studied, you can still participate by naming a new study partner to answer questions. The new partner would just need to answer some short qualifying questions and sign some paperwork.<br /> If you are the “care partner”, and the other person no longer wishes to participate, we can continue with your interview if the other person is agreeable to that.</p> 
            <h2><a href="#my-study-partner-has-passed-away-why-am-i-being-contacted-to-do-more" id="my-study-partner-has-passed-away-why-am-i-being-contacted-to-do-more" className='callLink'>My study partner has passed away, why am I being contacted to do more?</a></h2> 
            <p>Before anything else, we are very sorry for your loss. We recognize that it is a difficult time and realize you may not feel up to speaking with us at this time. When you feel ready, we would like to conduct a final interview to conclude your participation in our study. Our researchers are seeking to better understand the experiences of caregivers concerning health and end of life decisions for their loved ones in order to find areas that could use more resources. Of course, like all previous interviews, this can be done at your convenience.</p> 
            <h2><a href="#how-will-doing-this-help-me" id="how-will-doing-this-help-me" className='callLink'>How will doing this help me?</a></h2> 
            <p>Our study does not provide any interventions like medications or treatments. We offer a token of appreciation (a gift card upon the completion of each interview) for participating. You may not be directly helped by participating, but your responses are so important for contributing to what doctors and researchers know about memory and/or caregiving so that they can continue to look for answers. We also hope to share what we learn with leaders in our community. Every person who participates helps us take a step in the right direction!</p> 
        </div>
        </div>
        </Layout>
    )
}